/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";
import { SnackbarModel } from "./snackbar.interface";

const context = createContext<SnackbarModel>({
  setMessage: () => {},
  handleClose: () => {},
});

export default context;
