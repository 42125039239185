import "./Loading.css";

export const Loading = ({ description }: { description?: string }) => (
  <div className="content_main_spinner">
    <div className="fingerprint-spinner">
      <div className="spinner-ring"></div>
      <div className="spinner-ring"></div>
      <div className="spinner-ring"></div>
      <div className="spinner-ring"></div>
      <div className="spinner-ring"></div>
      <div className="spinner-ring"></div>
      <div className="spinner-ring"></div>
      <div className="spinner-ring"></div>
      <div className="spinner-ring"></div>
    </div>
    <p style={{ marginTop: "1rem" }}>{description || "Cargando..."}</p>
  </div>
);
