import { Alert, Snackbar } from "@mui/material";
import { FC, useContext, useState } from "react";
import SnackbarContext from "./Snackbar.context";
import { AnchorOriginInterface, SeverityMessage, SnackbarState } from "./snackbar.interface";

const initalState: SnackbarState = {
  message: "",
  open: false,
  severity: undefined,
  anchorOrigin: undefined,
};

export const useSnackbarContext = () => useContext(SnackbarContext);

interface SnackbarProviderInterface {
  children: any;
}

const SnackbarProvider: FC<SnackbarProviderInterface> = ({ children }) => {
  const [state, setState] = useState<SnackbarState>(initalState);

  const setMessage = (
    message: string,
    severity: SeverityMessage,
    anchorOrigin: AnchorOriginInterface
  ) => setState({ message, severity, open: true, anchorOrigin });

  const handleClose = () => setState({ ...state, open: false });

  const value = { setMessage, handleClose };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        open={state.open}
        autoHideDuration={8000}
        onClose={handleClose}
        anchorOrigin={state.anchorOrigin}
      >
        <Alert
          onClose={handleClose}
          severity={state.severity}
          sx={{ width: "100%" }}
          style={{
            backgroundColor:
              state.severity === "error"
                ? "var(--bg-tag-rejected)"
                : state.severity === "warning"
                ? "var(--bg-tag-sign-pending)"
                : state.severity === "info"
                ? "var(--bg-tag-sign-success)"
                : state.severity === "success"
                ? "var(--bg-tag-approval)"
                : "#ffffff6b",
            boxShadow: "var(--shadow-cards)",
            fontFamily: "Plus Jakarta Sans, sans-serif, -apple-system, Roboto, Ubuntu",
            display: "flex",
            alignItems: "center",
            backdropFilter: "blur(8px)",
            WebkitBackdropFilter: "blur(8px)",
          }}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
