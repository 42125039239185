import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import { Loading } from "./UI/Loading/Loading";
// Components
const View = lazy(() => import("./views/View"));
const Result = lazy(() => import("./views/Result"));
const Fingerprint = lazy(() => import("./views/Fingerprint"));
const Error404 = lazy(() => import("./UI/Error/Error404"));

function App() {
  const MultipleRoutes = () =>
    useRoutes([
      { path: "/:token", element: <View /> },
      { path: "/viewContract/:token", element: <Result /> },
      { path: "/fingerprint", element: <Fingerprint /> },
      { path: "*", element: <Error404 /> },
    ]);

  return (
    <React.Suspense fallback={<Loading />}>
      <MultipleRoutes />
    </React.Suspense>
  );
}

export default App;
