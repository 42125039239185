import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// Utils
import "./index.css";
import "./utils/i18n";
import App from "./App";
import SnackbarProvider from "./context/Snackbar/Snackbar.provider";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/assets/identy-worker-2d6920a91657fac7e508.js", {
      scope: "/assets/",
    })
    .then(function (registration) {
      console.log("Service Worker 1 registered with scope:", registration.scope);
    })
    .catch(function (error) {
      console.error("Service Worker 1 registration failed:", error);
    });
  navigator.serviceWorker
    .register("/assets/cache-worker-finger-9a907b44c8b46a3a8b6f_op.js", {
      scope: "/assets/",
    })
    .then(function (registration) {
      console.log("Service Worker 2 registered with scope:", registration.scope);
    })
    .catch(function (error) {
      console.error("Service Worker 2 registration failed:", error);
    });
}

const domNode = document.getElementById("root");

if (domNode === null) throw new Error("Root container missing in index.html");

const root = createRoot(domNode);

root.render(
  <StrictMode>
    <SnackbarProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SnackbarProvider>
  </StrictMode>,
);
